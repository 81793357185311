.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 100% !important;
}

.icon-close-stripe {
    font-size: 26px !important;
    cursor: pointer;
    color: #b6b7bb !important
}

.container-pix-info {
    width: 100%;
    border: 2px solid #efefef;
    border-radius: 8px;
    background: #f4f4f4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px;
}