.no-message {
    justify-content: center;
    align-items: flex-start;
    display: flex; 
}

.content-no-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}