#buttonOpen {
    padding: 6px 6px !important;
    min-width: 0px !important;
    border-radius: 50% !important;
}
  
.bar-modal-dialog {
      background-color: #15C163 ;
}
  
button:focus {
      outline: none !important;
}
      
.button-open {
    position: fixed !important;
    bottom: 20px !important;
    right: 20px !important;
    background-image: linear-gradient(98deg, #26EF81, #15C163 94%);
    box-shadow: none !important;
}

.button-add-client {
    background-image: linear-gradient(98deg, #26EF81, #15C163 94%);
    box-shadow: none !important;
    border-radius: 4px;
}

#buttonService {
    padding: 2px 2px !important;
    min-width: 0px !important;
    border-radius: 50% !important;
}

.button-service {
    background-color: #15C163 !important;
    box-shadow: none !important;
}

.icon-add-service {
    color: #ffffff !important;
    font-size: 26px !important;
}
    
.icon-add {
      color: #ffffff !important;
      font-size: 30px !important;
}
    
.button-open:hover {
    background-color: #13c163 !important;
    opacity: 0.9;
}

.css-m9glnp-MuiPaper-root-MuiDialog-paper  {
    background-color: #F4F4F4 !important;
}

.container-dialog {
    display: flex;
    justify-content: center;
    width: 100%;
}

.containerForm {
    padding-top: 40px;
    width: 100%;
    position: relative;
}

.form-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #3F3F3F
}

.button-submit-form {
    width: 100%;
    font-family: 'Inter', sans-serif !important;
    color: white !important; 
    font-size: 17px !important;
    background-color: #13c163 !important;
    border: #17A589 !important;

    position: absolute !important;
    bottom: 0px !important;
}

.button-submit-form:hover {
    opacity: 0.9;
}

.button-submit-form:focus, .button-submit-form:active {
    opacity: 0.9;
    box-shadow: none !important;
}

.button-submit-form:disabled {
    background-color: #ccc;
    border-color: #ccc;
    cursor: not-allowed; /* Altera o cursor para indicar que o botão está desabilitado */
}

.button-submit-form-material {
    position: absolute !important;
    bottom: 0px !important; 
    left: 0px !important;
}
  
.form-control-value-select {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ced4da;
}


.button-submit-form-service {
    width: 100%;
    font-family: 'Inter', sans-serif !important;
    color: white !important; 
    font-size: 17px !important;
    background-color: #13c163 !important;
    border: #17A589 !important;

    position: absolute !important;
    bottom: 0px !important;
}

.button-submit-form-service:hover {
    opacity: 0.9;
}

.button-submit-form-service:focus, .button-submit-form-service:active {
    opacity: 0.9;
    box-shadow: none !important;
}

.button-submit-form-service:disabled {
    background-color: #ccc;
    border-color: #ccc;
    cursor: not-allowed; /* Altera o cursor para indicar que o botão está desabilitado */
}






.button-submit-form-save {
    width: 100%;
    font-family: 'Inter', sans-serif !important;
    color: white !important; 
    font-size: 17px !important;
    background-color: #13c163 !important;
    border: #17A589 !important;
}

.button-submit-form-save:hover {
    opacity: 0.9;
}

.button-submit-form-save:focus, .button-submit-form-save:active {
    opacity: 0.9;
    box-shadow: none !important;
}

.button-submit-form-save:disabled {
    background-color: #ccc;
    border-color: #ccc;
    cursor: not-allowed; /* Altera o cursor para indicar que o botão está desabilitado */
}