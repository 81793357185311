/* Scheduling.css */
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}

/* .card {
  max-width: 400px;
  margin-bottom: 20px;
} */

.list-info {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 8PX;
}

.container-units {
  display: flex;
  justify-content: start;
  align-items: center;
}

.container-units span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #AEB0B2;
}

.container-units h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(58, 53, 65, 0.87);
  margin: 0px;
}

.form-search {
  display: flex;
  justify-content: end;
  align-items: center;
}

.form-control-value-select-search {
  /* width: 50%; */
  height: 40px;
  border-radius: 4px;
  border: 1px solid #f4f4f4;
  font-size: 14px;
}

.button-search-form {
  font-family: 'Inter', sans-serif !important;
  color: white !important; 
  font-size: 14px !important;
  background-color: #13c163 !important;
  border: #17A589 !important;
}

.button-search-form:hover {
  opacity: 0.9;
}

.button-search-form:focus, .button-search-form:active {
  opacity: 0.9;
  box-shadow: none !important;
}

.button-search-form:disabled {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed; /* Altera o cursor para indicar que o botão está desabilitado */
}

.MuiDialogActions-root {
  visibility: hidden !important;
}

.css-5cp2lg-MuiPickersLayout-root {
  border: 1px solid #13c163 !important;
  margin-bottom: 20px;
}