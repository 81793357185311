header {
    box-shadow: none !important;
}

.toolbar-responsible-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f5fb;
}

.toolbar-responsible {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f5fb;
}

.user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;
}

.drawer-sidebar {
    border: 1px solid red;
}

#dropdown-basic {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(58, 53, 65, 0.87)
}

#dropdown-basic:focus {
    box-shadow: none !important;
}

.style-options {
    font: normal normal bold 12px/15px !important;
    letter-spacing: 0 !important;
    text-decoration: none !important;
    text-align: left !important;
}

.style-text-color {
    color: #15C163;
    text-decoration: none !important; 
}
  
.style-text-color:hover {
    color: #15C163
}

/* buton dinamico ativo */
.active-link .MuiListItemIcon-root {
    color: #ffffff; /* cor desejada para o ícone quando o link estiver ativo */
}

.active-link .MuiListItemText-primary {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
}

.MuiListItemButton-root {
    border-left: 0px solid #f4f5fb !important;
    border-right: 0px solid #f4f5fb !important;
    border-top-right-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
    height: 40px !important;
    margin: 8px 10px 8px 0px !important;
}

.active-link .MuiListItemButton-root {
    box-shadow: 0px 4px 8px -4px rgba(58, 53, 65, 0.42);
    background-image: linear-gradient(98deg, #26EF81, #15C163 94%);
}

@media (max-width: 500px) { 
    #dropdown-basic {
        display: none;
    }
}