.partner-card {
  position: relative;
  border-radius: 4px !important;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}

.partner-status-active {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #14D2AA;
  border-radius: 100px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.partner-status-inactive {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  border-radius: 100px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.card-actions {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
}

.card-cartion-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
  
.button-card-action {
  margin-left: 8px;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #F44336 !important;
  border: 1px solid #F44336 !important;
}

.button-card-action:hover {
  opacity: 0.9 !important;
  background-color: rgba(244, 67, 54, 0.2) !important;
}



.button-card-action-update {
  margin-left: 8px;
  padding: 5px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  background-color: #18a589 !important;
  color: #FFF !important;
  border: 1px solid #18a589 !important;
}

.button-card-action-update:hover {
  opacity: 0.9 !important;
  background-color: #18a589!important;
}

.activate-button {
  background-color: #4caf50;
  color: #fff;
}

.deactivate-button {
  background-color: #f44336;
  color: #fff;
}

.update-button {
  background-color: #2196f3;
  color: #fff;
}

.card-actions button:hover {
  filter: brightness(0.9);
}
  
  

.list-info {
    cursor: pointer;
}

.list-info:hover {
    background-color: #efefef;
}

.typography-encaminhamento {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 27px !important;
  font-weight: bold !important;
  color: #3F3F3F !important;
}