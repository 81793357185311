@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');



.box-login {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 96vh;
}

#linha-vertical {
    margin-top: 200px;
    height: 450px;
    border-right: 1px solid #71C4BC;
}

.right {
    min-width: 500px;
}

.left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-left {
    width: 250px;
    height: 250px;
}

.img-top {
    height: 100px;
}

.paragrafo-login {
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #373736;
    margin: 0px 0px 20px 0px;
}

form {
    font-family: 'Inter', sans-serif;
    font-size: 17px;
}

.auxiliar {
    display: flex;
    flex-direction: column;
}

.dados-login {
    display: flex;
    flex-direction: column;
}

.esqueceu-senha {
    display: flex;
    justify-content: flex-end;
    margin: 2px 8px 25px 0px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #C4C4C4;
}

.button-login {
    width: 100%;
    font-family: 'Inter', sans-serif !important;
    color: white !important; 
    font-size: 17px !important;
    background-color: #13c163 !important;
    border: #17A589 !important;
}

.button-login:hover {
    opacity: 0.9;
}

.button-login:focus, .button-login:active {
    opacity: 0.9;
    box-shadow: none !important;
}

.button-login:disabled {
    background-color: #ccc;
    border-color: #ccc;
    cursor: not-allowed; /* Altera o cursor para indicar que o botão está desabilitado */
}

a {
    text-decoration: none;
    color: white;
}

@media (max-width: 600px) {
    .right {
        min-width: 80%;
    }
}

.error-message {
    color: red;
}
